import styled from "styled-components";
import Bg from "../../images/4.svg";
export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  /* height: 70vh; */
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Background = styled.div`
  display: flex;
  flex: 0.5;
  background: url(${Bg});
  background-size: 100% 100%;
  height: 60vh;
  border-radius: 10px;

  @media screen and (max-width: 960px) {
    display: none;
    height: 30vh;
  }
`;

export const Form = styled.div`
  display: flex;
  flex: 0.5;
  justify-content: center;
  margin: auto 0;
  margin-top: 7%;

  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100vh;
    margin-left: -10px;
  }
`;
