import React, { useState, useEffect } from "react";
import { Card, Spin } from "antd";
import {
  SlackOutlined,
  OneToOneOutlined,
  UserOutlined,
  MailOutlined,
  MehOutlined,
  FundProjectionScreenOutlined,
  HeatMapOutlined,
  AppstoreAddOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";
import axios from "axios";
import BASE_URL from "../../constant";

const Index = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(0);
  const [notices, setNotices] = useState(0);
  const [poems, setPoems] = useState(0);
  const [tickers, setTickers] = useState(0);
  const [feedbacks, setFeedbacks] = useState(0);
  const [contacts, setContacts] = useState(0);
  const [directors, setDirectors] = useState(0);
  const [cremotrium, setCremotrium] = useState(0);

  // Headers
  const axiosHeaders = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };

  const UserCount = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `${BASE_URL}/api/usercount`,
      headers: axiosHeaders,
    }).then((response) => {
      setUsers(response.data.usercount);
      setLoading(false);
    });
  };

  const FeedbackCount = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `${BASE_URL}/api/feedbackcount`,
      headers: axiosHeaders,
    }).then((response) => {
      setFeedbacks(response.data.feedbackcount);
      setLoading(false);
    });
  };

  const ObituryCount = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `${BASE_URL}/api/obiturycount`,
      headers: axiosHeaders,
    }).then((response) => {
      setNotices(response.data.obiturycount);
      setLoading(false);
    });
  };

  const ContactCount = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `${BASE_URL}/api/contactcount`,
      headers: axiosHeaders,
    }).then((response) => {
      setContacts(response.data.contactcount);
      setLoading(false);
    });
  };

  const PoemCount = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `${BASE_URL}/api/poemcount`,
      headers: axiosHeaders,
    }).then((response) => {
      setPoems(response.data.poemcount);
      setLoading(false);
    });
  };

  const TickerCount = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `${BASE_URL}/api/tickercount`,
      headers: axiosHeaders,
    }).then((response) => {
      setTickers(response.data.tickercount);
      setLoading(false);
    });
  };

  const CremotriumCount = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `${BASE_URL}/api/cremotriumcount`,
      headers: axiosHeaders,
    }).then((response) => {
      setCremotrium(response.data.cremotrium);
      setLoading(false);
    });
  };

  const DirectorCount = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `${BASE_URL}/api/directorscount`,
      headers: axiosHeaders,
    }).then((response) => {
      setDirectors(response.data.directors);
      setLoading(false);
    });
  };

  useEffect(() => {
    UserCount();
    FeedbackCount();
    ObituryCount();
    ContactCount();
    PoemCount();
    TickerCount();
    CremotriumCount();
    DirectorCount();
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <Spin size="large" style={{ padding: 50, margin: "20% auto" }} />
        ) : (
          <>
            <Card
              bordered={true}
              style={{ ...cardStyle, backgroundColor: "#0f0c29" }}
            >
              <h1 style={textStyle}>
                <UserOutlined />
                {`  `}
                {users} users
              </h1>
            </Card>
            <Card
              bordered={true}
              style={{ ...cardStyle, backgroundColor: "#89216b" }}
            >
              <h1 style={textStyle}>
                {" "}
                <SlackOutlined />
                {`  `}
                {notices} Notice
              </h1>
            </Card>
            <Card
              bordered={true}
              style={{ ...cardStyle, backgroundColor: "#11998e" }}
            >
              <h1 style={textStyle}>
                {" "}
                <OneToOneOutlined />
                {`  `}
                {poems} Poems
              </h1>
            </Card>
            <Card
              bordered={true}
              style={{ ...cardStyle, backgroundColor: "#0575e6" }}
            >
              <h1 style={textStyle}>
                {" "}
                <FundProjectionScreenOutlined />
                {`  `}
                {tickers} Ticker
              </h1>
            </Card>
            <Card
              bordered={true}
              style={{ ...cardStyle, backgroundColor: " #fc4a1a" }}
            >
              <h1 style={textStyle}>
                {" "}
                <MehOutlined />
                {`  `}
                {feedbacks} Feedbacks
              </h1>
            </Card>

            <Card
              bordered={true}
              style={{ ...cardStyle, backgroundColor: "#f8b500" }}
            >
              <h1 style={textStyle}>
                {" "}
                <MailOutlined />
                {`  `}
                {contacts} Contacts
              </h1>
            </Card>

            <Card
              bordered={true}
              style={{ ...cardStyle, backgroundColor: " #ee0979" }}
            >
              <h1 style={textStyle}>
                {" "}
                <AppstoreAddOutlined />
                {`  `}
                {directors} Funeral Directors
              </h1>
            </Card>

            <Card
              bordered={true}
              style={{ ...cardStyle, backgroundColor: " #45b649" }}
            >
              <h1 style={textStyle}>
                {" "}
                <HeatMapOutlined />
                {`  `}
                {cremotrium} Crematorium
              </h1>
            </Card>

            <Card
              bordered={true}
              style={{ ...cardStyle, backgroundColor: " #41295a" }}
            >
              <h1 style={textStyle}>
                {" "}
                <CarryOutOutlined />
                {`  `}
                {feedbacks} Remembrances
              </h1>
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

const cardStyle = {
  width: 330,
  margin: 10,
  borderRadius: 7,
  padding: 10,
  textAlign: "center",
  backgroundColor: "tomato",
  color: "white",
  cursor: "pointer",
};

const textStyle = {
  color: "#fff",
  // fontWeight: "bold",
  fontSize: 25,
};

export default Index;
