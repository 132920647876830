import React, { useState, useContext } from "react";
import { Layout, Menu, Avatar } from "antd";
import {
  DesktopOutlined,
  LogoutOutlined,
  UserOutlined,
  PlayCircleOutlined,
  SwapOutlined,
  WindowsOutlined,
  HeartOutlined,
  MessageOutlined,
  CalendarOutlined,
  AlertOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../App";
const { Sider } = Layout;

const Index = () => {
  const { state, dispatch } = useContext(UserContext);
  const history = useHistory();
  const [collapse, setCollapse] = useState(false);

  //   Function to collapse => activated
  const toggleCollapsed = () => {
    setCollapse(!collapse);
  };

  return (
    <div>
      <Sider
        width={250}
        breakpoint="xxl"
        collapsible
        collapsed={collapse}
        onCollapse={toggleCollapsed}
        style={{ height: "100%" }}
      >
        <div
          className="logo"
          style={{
            display: "inline-block",
            padding: 20,
            color: "white",
            // backgroundColor: "blue",
            width: "100%",
          }}
        >
          <Avatar
            size="large"
            style={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              marginRight: 10,
            }}
          >
            A
          </Avatar>
          Admin
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          style={{ fontSize: 17 }}
        >
          <Menu.Item key="1" icon={<WindowsOutlined />}>
            <Link to="/dashboard">Dashboard </Link>
          </Menu.Item>
          <Menu.Item key="123" icon={<PlayCircleOutlined />}>
            <Link to="/notice">Notice Requests</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<SwapOutlined />}>
            <Link to="/ticker">Tickers</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<DesktopOutlined />}>
            <Link to="/poem">Poems</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<UserOutlined />}>
            <Link to="/users">Users </Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<HeartOutlined />}>
            <Link to="/feedback">Feedbacks </Link>
          </Menu.Item>
          <Menu.Item key="6d324" icon={<CalendarOutlined />}>
            <Link to="/directors">Funeral Directors </Link>
          </Menu.Item>
          <Menu.Item key="7234" icon={<AlertOutlined />}>
            <Link to="/cremotrium">Crematorium </Link>
          </Menu.Item>
          <Menu.Item key="8" icon={<MessageOutlined />}>
            <Link to="/contact">Contacts </Link>
          </Menu.Item>
          <Menu.Item
            style={{ color: "red" }}
            key="7"
            icon={<LogoutOutlined />}
            onClick={() => {
              localStorage.clear();
              dispatch({ type: "CLEAR" });
              history.push("/");
            }}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
    </div>
  );
};

export default Index;
