import React, { useState, useEffect } from "react";
import {
  DeleteOutlined,
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { Table, Tag, Modal } from "antd";
import BASE_URL from "../../constant/index";
import swal from "sweetalert";

const Index = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [alldata, setAlldata] = useState([]);
  const [keyid, setKeyid] = useState("");
  const [deletevisible, setDeleteVisible] = useState(false);
  const [publishvisible, setPublishvisible] = useState(false);
  const [unpublishvisible, setUnpublishvisible] = useState(false);
  // Headers
  const axiosHeaders = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };

  const publishData = (_id) => {
    setLoading(true);
    axios({
      method: "put",
      url: `${BASE_URL}/puplishobitury/${_id}`,
      headers: axiosHeaders,
    }).then((response) => {
      swal({
        timer: 3000,
        title: "Published",
        text: "sucessfully Published !",
        icon: "success",
        button: "Okey",
        button: false,
      });
      refreshData();
      setLoading(false);
      setKeyid("");
    });
  };
  const unpublishData = (_id) => {
    setLoading(true);
    axios({
      method: "put",
      url: `${BASE_URL}/unpuplishobitury/${_id}`,
      headers: axiosHeaders,
    }).then((response) => {
      swal({
        timer: 3000,
        title: "Unpublished !",
        text: "sucessfully Un Published !",
        icon: "warning",
        button: "done",
        button: false,
      });
      refreshData();
      setLoading(false);
      setKeyid("");
    });
  };

  const refreshData = () => {
    // setLoading(true);
    const axiosHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    axios({
      method: "get",
      url: `${BASE_URL}/allobitury`,
      headers: axiosHeaders,
    }).then((response) => {
      console.log(response.data.obitury);
      setAlldata(response.data.obitury);
      setLoading(false);
    });
  };

  const data = [];
  for (let i = 0; i < 1; i++) {
    alldata.map((item) => {
      data.push({
        key: item._id,
        name: item.title + `.  ` + item.namedeceased,
        age: item.age,
        dateofbirth: item.dateofbirth,
        dateofdeceased: item.dateofdeceased,
        birthplace: item.birthplace,
        deathplace: item.deathplace,
        showstatus: item.showstatus,
      });
    });
  }

  // Delete Modal functions
  const showModalDelete = () => {
    setDeleteVisible(true);
  };
  const hideModalDelete = () => {
    setDeleteVisible(false);
  };

  const deletePost = (obituryId) => {
    axios({
      method: "delete",
      url: `${BASE_URL}/admindeleteobitury/${obituryId}`,
      headers: axiosHeaders,
    }).then((result) => {
      swal({
        timer: 3000,
        title: "Deleted !",
        text: "sucessfully Deleted !",
        icon: "error",
        button: "done",
        button: false,
      });
      refreshData();
      setLoading(false);
      setKeyid("");
      // history.push("/notice");
    });
  };

  // Columns for Data table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a href="#">{text}</a>,
    },

    {
      title: "Status",
      dataIndex: "showstatus",
      key: "showstatus",
      render: (text) => (
        <>
          {text ? (
            <Tag
              style={{
                color: "white",
                backgroundColor: "green",
                padding: 4,
                borderRadius: 5,
                marginRight: 5,
                width: "100%",
                textAlign: "center",
              }}
            >
              <CheckOutlined /> Published
            </Tag>
          ) : (
            <Tag
              style={{
                color: "white",
                backgroundColor: "red",
                padding: 4,
                borderRadius: 5,
                marginRight: 5,
                width: "100%",
                textAlign: "center",
              }}
            >
              <CloseOutlined /> Not Published
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Date of birth",
      dataIndex: "dateofbirth",
      key: "dateofbirth",
      render: (text) => (
        <Tag color={"cyan"} key={text}>
          {text}
        </Tag>
      ),
    },
    {
      title: "Date of death",
      dataIndex: "dateofdeceased",
      key: "dateofdeceased",
      render: (text) => (
        <Tag color={"purple"} key={text}>
          {text}
        </Tag>
      ),
    },
    {
      title: "Birth place",
      dataIndex: "birthplace",
      key: "birthplace",
      render: (text) => (
        <Tag color={"orange"} key={text}>
          {text}
        </Tag>
      ),
    },
    {
      title: "Death place",
      dataIndex: "deathplace",
      key: "deathplace",
      render: (text) => (
        <Tag color={"blue"} key={text}>
          {text}
        </Tag>
      ),
    },
    {
      title: "Publish Content",
      key: "publish",
      render: (text, record) => (
        <>
          <span>
            <a
              onClick={(e) => {
                setPublishvisible(true);
                setKeyid(record.key, e);
              }}
              style={{
                color: "white",
                backgroundColor: "blue",
                padding: 4,
                borderRadius: 5,
                marginRight: 5,
              }}
            >
              <CheckOutlined /> Puplish
            </a>
          </span>
          <span>
            <a
              onClick={(e) => {
                setKeyid(record.key, e);
                setUnpublishvisible(true);
              }}
              style={{
                color: "white",
                backgroundColor: "purple",
                padding: 4,
                borderRadius: 5,
              }}
            >
              <CloseOutlined /> Un Publish
            </a>
          </span>
        </>
      ),
    },

    {
      title: "Operations",
      key: "action",
      render: (text, record) => (
        <>
          <span>
            <Link
              onClick={(e) => {
                history.push(`/fullview/${record.key}`);
              }}
              style={{
                color: "white",
                marginRight: 10,
                backgroundColor: "darkcyan",
                padding: 4,
                borderRadius: 5,
                paddingLeft: 7,
                paddingRight: 7,
                fontSize: 20,
              }}
            >
              <EyeOutlined />
            </Link>

            <a
              onClick={(e) => {
                setDeleteVisible(true);
                setKeyid(record.key, e);
              }}
              style={{
                color: "white",
                marginRight: 10,
                backgroundColor: "red",
                padding: 4,
                paddingLeft: 7,
                paddingRight: 7,
                borderRadius: 5,
                fontSize: 20,
              }}
            >
              <DeleteOutlined />
            </a>
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    refreshData();
  }, [alldata]);

  return (
    <div>
      <Table
        style={{ zoom: "85%" }}
        // bordered
        columns={columns}
        dataSource={data}
        loading={loading}
      />

      <Modal
        title="Publish Confirmation"
        visible={publishvisible}
        onOk={(e) => {
          publishData(keyid);
          setPublishvisible(false);
        }}
        onCancel={() => {
          setPublishvisible(false);
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <ExclamationCircleOutlined
            style={{ fontSize: 50, color: "green", margin: 10 }}
          />
          <p style={{ marginTop: 20, color: "gray", fontSize: 18 }}>
            {" "}
            Please confirm your Publish yourdata...
          </p>
        </div>
      </Modal>

      <Modal
        title="Un Publish Confirmation"
        visible={unpublishvisible}
        onOk={(e) => {
          unpublishData(keyid);
          setUnpublishvisible(false);
        }}
        onCancel={() => {
          setUnpublishvisible(false);
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <ExclamationCircleOutlined
            style={{ fontSize: 50, color: "orange", margin: 10 }}
          />
          <p style={{ marginTop: 20, color: "gray", fontSize: 18 }}>
            {" "}
            Please confirm that you Un Publishing yourdata...
          </p>
        </div>
      </Modal>

      <Modal
        title="Complete delete Confirmation"
        visible={deletevisible}
        onOk={(e) => {
          deletePost(keyid);
          setDeleteVisible(false);
        }}
        onCancel={() => {
          setDeleteVisible(false);
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <CloseCircleOutlined
            style={{ fontSize: 50, color: "red", margin: 10 }}
          />
          <p style={{ marginTop: 20, color: "gray", fontSize: 18 }}>
            {" "}
            Please confirm to completely delete data...
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
