import React, { useState, useEffect } from "react";
import { Collapse, List, Spin } from "antd";
import axios from "axios";
import moment from "moment";
import BASE_URL from "../../constant/index";
import { MessageOutlined } from "@ant-design/icons";
const { Panel } = Collapse;

const Index = () => {
  const [loading, setLoading] = useState(true);
  const [alldata, setAlldata] = useState([]);

  // Axios headers
  const axiosHeaders = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };

  console.log(alldata[0]);

  // Get all data request
  const refreshData = () => {
    // setLoading(true);

    axios({
      method: "get",
      url: `${BASE_URL}/allcontact`,
      headers: axiosHeaders,
    }).then((response) => {
      console.log(response.data.allcontact);
      setAlldata(response.data.allcontact);
      setLoading(false);
    });
  };

  useEffect(() => {
    refreshData();
  }, [alldata]);

  return (
    <div>
      {loading ? (
        <Spin style={{ width: "100%", marginTop: "40vh" }} size="large" />
      ) : (
        <List
          header={
            <h3
              style={{
                color: "crimson",
                fontSize: 20,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              All Contacts ...
            </h3>
          }
          dataSource={alldata}
          renderItem={(item) => (
            <>
              <Collapse
                accordion={true}
                bordered={true}
                defaultActiveKey={item[0]}
              >
                <Panel
                  header={item.name}
                  key={item._id}
                  extra={
                    <span
                      style={{
                        fontWeight: "100",
                        fontSize: 14,
                        color: "green",
                      }}
                    >
                      {" "}
                      {moment(item.createdAt).format("YYYY / DD / MM") +
                        moment(item.createdAt).format(" HH:MM A") +
                        "  " +
                        moment(item.createdAt).format("dddd")}
                    </span>
                  }
                >
                  <List.Item>
                    <List.Item.Meta
                      avatar={<MessageOutlined />}
                      title={
                        <span style={listStyles}>
                          <span className="listname">Email :</span>{" "}
                        </span>
                      }
                      description={
                        <span className="listdescription">{item.email}</span>
                      }
                    />
                    <List.Item.Meta
                      avatar={<MessageOutlined />}
                      title={
                        <span style={listStyles}>
                          <span className="listname">Mobile :</span>{" "}
                        </span>
                      }
                      description={
                        <span className="listdescription">{item.phone}</span>
                      }
                    />
                  </List.Item>

                  <List.Item>
                    <List.Item.Meta
                      avatar={<MessageOutlined />}
                      title={
                        <span style={listStyles}>
                          <span className="listname">Message :</span>{" "}
                        </span>
                      }
                      description={
                        <span className="listdescription">{item.message}</span>
                      }
                    />
                  </List.Item>
                </Panel>
              </Collapse>
            </>
          )}
        />
      )}
    </div>
  );
};

const listStyles = {
  color: "gray",
  fontSize: 19,
  marginRight: 10,
};

export default Index;
