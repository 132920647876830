import React, {
  useState,
  useEffect,
  createContext,
  useReducer,
  useContext,
} from "react";
import { reducer, initialstate } from "./reducers/userReducer";
import { Layout } from "antd";
import {
  BrowserRouter as BrowserRouter,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";

// Screens import
import Sidebar from "./screens/Sidebar";
import Ticker from "./screens/Ticker";
import Dashboard from "./screens/Dashboard";
import Poem from "./screens/Poem";
import Directors from "./screens/Directors";
import Cremotorium from "./screens/Cremotorium";
import Contacts from "./screens/Contacts";
import Feedbacks from "./screens/Feedbacks";
import Notices from "./screens/Notices";
import Users from "./screens/Users";
import Login from "./screens/SignIn/SignIn";
import Detailview from "./screens/Detailview/Detailview";

// Exporting user context for global level state management
export const UserContext = createContext();
const { Header, Content, Footer } = Layout;
// All routes path
const Routing = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(UserContext);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      dispatch({ type: "USER", payload: user });
      if (history.location.pathname.startsWith("/")) history.push("/dashboard");
    } else {
      console.log("else");
    }
  }, []);
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route path="/ticker" component={Ticker} />
      <Route path="/poem" component={Poem} />
      <Route path="/notice" component={Notices} />
      <Route path="/feedback" component={Feedbacks} />
      <Route path="/contact" component={Contacts} />
      <Route path="/users" component={Users} />
      <Route path="/login" component={Login} />
      <Route path="/directors" component={Directors} />
      <Route path="/cremotrium" component={Cremotorium} />
      <Route exact path="/fullview/:id" component={Detailview} />
    </Switch>
  );
};

const MainPanel = () => {
  const [useravalable, setuserAvailable] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setuserAvailable(true);
    } else {
      setuserAvailable(false);
    }
  });
  return (
    <Layout style={{ minHeight: "100vh" }}>
      {useravalable ? <Sidebar style={{ width: 800 }} /> : <></>}

      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{ padding: 0, backgroundColor: "transparent" }}
        />
        <Content
          style={{
            margin: "0 16px",
            backgroundColor: "white",
            borderRadius: 10,
          }}
        >
          <Routing />
        </Content>
        <Footer
          style={{
            textAlign: "center",
            // color: "white",
            // backgroundColor: "#1890ff",
          }}
        >
          passingaway ©2021 Created by #Mathan
        </Footer>
      </Layout>
    </Layout>
  );
};

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialstate);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        <MainPanel />
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default App;
