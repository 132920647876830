import React, { useState, useEffect } from "react";
import { Collapse, List, Spin, Button, Drawer, Input, Modal } from "antd";
import axios from "axios";
// import moment from "moment";
import CKEditor from "ckeditor4-react";
import BASE_URL from "../../constant/index";
import {
  MessageOutlined,
  FormOutlined,
  DeleteOutlined,
  AlertOutlined,
} from "@ant-design/icons";
import swal from "sweetalert";
import { AiOutlineLeftCircle } from "react-icons/ai";

const { Panel } = Collapse;

const Index = () => {
  const [loading, setLoading] = useState(true);
  const [alldata, setAlldata] = useState([]);
  const [visible, setVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [error, setError] = useState("");
  const [update, setUpdate] = useState(false);
  const [keyid, setKeyid] = useState("");
  const [deletevisible, setDeletevisible] = useState(false);

  // Axios headers
  const axiosHeaders = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };

  // Get all data request
  const refreshData = () => {
    axios({
      method: "get",
      url: `${BASE_URL}/api/alldirectors`,
      headers: axiosHeaders,
    }).then((response) => {
      console.log(response.data.alldirectors);
      setAlldata(response.data.alldirectors);
      setLoading(false);
    });
  };

  // Add Poem to request

  const postData = () => {
    if (description === "" || name === "" || country == "") {
      setError("* Please fill all Cremotrium data to submit..");
    } else {
      setLoading(true);
      axios({
        method: "post",
        url: `${BASE_URL}/api/createdirector`,
        headers: axiosHeaders,
        data: {
          country: country,
          name: name,
          description: description,
        },
      }).then((response) => {
        swal({
          timer: 3000,
          title: "Posted",
          text: "sucessfully Added new Funeral director !",
          icon: "success",
          button: "Okey",
          button: false,
        });
        refreshData();
        setVisible(false);
        setLoading(false);
        setDescription("");
        setName("");
      });
    }
  };

  // Updating the ticker
  const updateData = (_id) => {
    if (description === "") {
      setError("* Please fill the ticker data to submit..");
    } else {
      setLoading(true);
      axios({
        method: "put",
        url: `${BASE_URL}/api/updatedirector`,
        headers: axiosHeaders,
        data: {
          _id: _id,
          country: country,
          name: name,
          description: description,
        },
      }).then((response) => {
        swal({
          timer: 3000,
          title: "Updated",
          text: "sucessfully Updated Funeral director !",
          icon: "success",
          button: "Okey",
          button: false,
        });
        refreshData();
        setVisible(false);
        setLoading(false);
        setDescription("");
        setKeyid("");
        setCountry("");
        setName("");
        setUpdate(false);
      });
    }
  };

  const deletePost = (_id) => {
    setLoading(true);
    axios({
      method: "delete",
      url: `${BASE_URL}/api/deletedirector`,
      headers: axiosHeaders,
      data: {
        _id: _id,
      },
    }).then((response) => {
      swal({
        timer: 3000,
        title: "Deleted",
        text: "sucessfully Deleted  Funeral director !",
        icon: "success",
        button: "Okey",
        button: false,
      });
      refreshData();
      setVisible(false);
      setLoading(false);
      setKeyid("");
    });
  };

  useEffect(() => {
    refreshData();
  }, [alldata]);

  return (
    <div>
      {loading ? (
        <Spin style={{ width: "100%", marginTop: "40vh" }} size="large" />
      ) : (
        <>
          <Button
            onClick={() => {
              setVisible(true);
              setDescription("");
            }}
            type="primary"
            style={{ width: 300, borderRadius: 50, margin: 10 }}
          >
            + Add New Funeral Director
          </Button>
          <List
            accordion
            header={
              <h3
                style={{
                  color: "#808",
                  fontSize: 20,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                All Funeral Directors ...
              </h3>
            }
            dataSource={alldata}
            renderItem={(item) => (
              <>
                <Collapse
                  accordion={true}
                  bordered={true}
                  defaultActiveKey={item[0]}
                >
                  <Panel
                    header={
                      <span
                        style={{
                          display: "block",
                          width: 400,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {" "}
                        {item.name}{" "}
                      </span>
                    }
                    key={item._id}
                    extra={
                      <>
                        <span
                          onClick={() => {
                            setKeyid(item._id);
                            setVisible(true);
                            setCountry(item.country);
                            setName(item.name);
                            setDescription(item.description);
                            setUpdate(true);
                          }}
                          style={{
                            padding: 10,
                            backgroundColor: "#234",
                            fontWeight: "bold",
                            fontSize: 14,
                            color: "green",
                            color: "white",
                            borderRadius: 5,
                            marginRight: 10,
                          }}
                        >
                          <FormOutlined /> update
                        </span>
                        <span
                          onClick={() => {
                            setKeyid(item._id);
                            // setDeletevisible(true);
                            Modal.confirm({
                              okText: "Confirm",
                              title: "Make sure !",
                              visible: deletevisible,
                              onOk: () => deletePost(keyid),
                              onCancel: () => setDeletevisible(false),
                              cancelText: "cancel",
                              content:
                                "Do you want to delete this cremotrium...",
                            });
                          }}
                          style={{
                            padding: 10,
                            backgroundColor: "red",
                            fontWeight: "100",
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "white",
                            borderRadius: 5,
                          }}
                        >
                          <DeleteOutlined /> Delete
                        </span>
                      </>
                    }
                  >
                    <List.Item>
                      <List.Item.Meta
                        avatar={<AlertOutlined />}
                        title={
                          <span style={listStyles}>
                            <span className="listname">Country :</span>{" "}
                          </span>
                        }
                        description={
                          <>
                            <span style={{ color: "red" }}>{item.country}</span>
                          </>
                        }
                      />
                    </List.Item>
                    <List.Item>
                      <List.Item.Meta
                        avatar={<AlertOutlined />}
                        title={
                          <span style={listStyles}>
                            <span className="listname">Details :</span>{" "}
                          </span>
                        }
                        description={
                          <>
                            <div
                              className="descriptionView"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                          </>
                        }
                      />
                    </List.Item>
                  </Panel>
                </Collapse>
              </>
            )}
          />
        </>
      )}

      {/* DRAWER FOR ADD AND UPDATE */}
      <Drawer
        footer={
          <>
            {update ? (
              <Button
                style={{ float: "right", marginLeft: 10, fontWeight: "bold" }}
                type="primary"
                onClick={() => updateData(keyid)}
              >
                <FormOutlined /> Update Data
              </Button>
            ) : (
              <Button
                style={{
                  float: "right",
                  marginLeft: 10,
                  fontWeight: "bold",
                }}
                type="primary"
                onClick={() => postData()}
              >
                Add Data
              </Button>
            )}

            <Button
              style={{ float: "right" }}
              onClick={() => setVisible(false)}
            >
              cancel
            </Button>
          </>
        }
        width={800}
        title="Add Your Funeral director "
        placement="right"
        closable={false}
        onClose={() => {
          setVisible(false);
          setUpdate(false);
          setDescription("");
        }}
        visible={visible}
      >
        <label>Country:</label>
        <Input
          style={{ marginBottom: 10 }}
          required
          size="large"
          placeholder="Country of cremotrium "
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <label>Title:</label>
        <Input
          style={{ marginBottom: 10 }}
          required
          size="large"
          placeholder="Title of cremotrium "
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label>Description:</label>
        <span style={{ color: "tomato" }}>
          {"  ->"} You can add contact and address like details here
        </span>
        <CKEditor
          style={{ marginBottom: 10 }}
          data={description}
          placeholder="You can add contact and address here"
          onChange={(evt) => setDescription(evt.editor.getData())}
        />

        <span style={{ color: "red", fontSize: 16, marginTop: 20 }}>
          {error}
        </span>
      </Drawer>
    </div>
  );
};

const listStyles = {
  color: "gray",
  fontSize: 19,
  marginRight: 10,
};

export default Index;
