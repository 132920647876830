import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { List, Carousel, Spin } from "antd";
import {
  MainContainer,
  ContentContainer,
  WishesContainer,
  CandleWrapper,
  CandleContainer,
  CandleImg,
  CandleDate,
  ImageWrapper,
  ImageContainer,
  RipimageChild,
  RipimageParent,
  Text,
  Column,
  Row,
  DetailContents,
  HairlineSeperator,
  CarouselItem,
  Container,
} from "../Detailview/DetailView.elements";

import Gif from "../../images/dd.gif";
import Headstoneimg from "../../images/headstone.png";
import {
  BsBookmarkPlus,
  BsPhone,
  BsExclamationDiamond,
  BsChatQuote,
} from "react-icons/bs";
import { AiOutlineLeftCircle } from "react-icons/ai";
import BASE_URL from "../../constant/index";

function Index(props) {
  const history = useHistory();
  const [alldata, setAlldata] = useState();
  const [loading, setLoading] = useState(false);
  const [imgArray, setimgArray] = useState([]);
  const [tributes, setTributes] = useState([]);
  const [basedata, setBasedata] = useState();

  // DUMMY DATA
  const [tributedata, setTributeData] = useState([
    {
      title: "Their posted tributes console here",
      postedBy: "Mr.Someone",
    },
    {
      title: "Their posted tributes console here",
      postedBy: "Mr.Someone",
    },
  ]);

  const refreshData = () => {
    setLoading(true);
    const axiosHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    axios({
      method: "get",
      url: `${BASE_URL}/updateu/${props.match.params.id}`,
      headers: axiosHeaders,
    }).then((response) => {
      console.log(response.data);
      setAlldata(response.data);
      setimgArray(response.data.imgCollection);
      setBasedata([
        {
          title: response.data.title,
          name: response.data.namedeceased,
          age: response.data.age,
          dateofbirth: response.data.dateofbirth,
          birthplace: response.data.birthplace,
          citydeceased: response.data.citydeceased,
          jobtitle: response.data.jobtitle,
          dateofdeath: response.data.dateofdeceased,
          deathplace: response.data.deathplace,
          lastemployment: response.data.lastemployment,
          iname: response.data.iname,
          iemail: response.data.iemail,
          iaddress: response.data.iaddress,
          imobile: response.data.imobile,
          kfullname: response.data.kfullname,
          kphone: response.data.kphone,
          krelationship: response.data.krelationship,
          kpostalcode: response.data.kpostalcode,
          kemail: response.data.kemail,
          kcity: response.data.kcity,
          kviewlocation: response.data.kviewlocation,
          kviewingdatetime: response.data.kviewingdatetime,
          kceremonylocation: response.data.kceremonylocation,
          kceremonydatetime: response.data.kceremonydatetime,
          kburialdateandtime: response.data.kburialdateandtime,
          kburiallocation: response.data.kburiallocation,
        },
      ]);
      setTributes(response.data.comments);
      setLoading(false);

      // setAlldata(response.data.find((o) => o._id == props.match.params.id));
    });
  };

  useEffect(() => {
    refreshData();
    if (tributedata === undefined || tributedata.length == 0) {
      setTributeData([{}]);
    }
  }, []);

  return (
    <>
      <Container>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "130vh",
            }}
          >
            <Spin size="large" tip="Loading..."></Spin>
          </div>
        ) : (
          <>
            <p
              style={{
                color: "white",
                backgroundColor: "green",
                padding: 5,
                fontSize: 20,
                marginTop: 10,
              }}
            >
              <span
                onClick={() => history.push("/notice")}
                style={{
                  backgroundColor: "black",
                  padding: 5,
                  cursor: "pointer",
                  color: "white",
                }}
              >
                {" "}
                <AiOutlineLeftCircle /> Back
              </span>{" "}
              ** This is the main content which scale by the user posted and you
              all have the right to approve to the main console display !
            </p>
            <MainContainer style={{ marginTop: -20 }}>
              <ContentContainer>
                <Column>
                  <Row>
                    <CandleWrapper>
                      <CandleContainer>
                        <CandleImg src={Gif} />
                        <CandleDate>
                          Born Date {alldata ? alldata.dateofbirth : "loading"}
                        </CandleDate>
                      </CandleContainer>
                    </CandleWrapper>
                    <ImageWrapper>
                      <ImageContainer>
                        <RipimageParent
                          src={alldata ? alldata.frameimage : "loading"}
                        />
                        <RipimageChild
                          src={alldata ? alldata.deceasedimage : "loading"}
                        />
                      </ImageContainer>
                    </ImageWrapper>
                    <CandleWrapper>
                      <CandleContainer>
                        <CandleImg src={Gif} />
                        <CandleDate>
                          Rest Date{" "}
                          {alldata ? alldata.dateofdeceased : "loading"}{" "}
                        </CandleDate>
                      </CandleContainer>
                    </CandleWrapper>
                  </Row>

                  <Text scaleSmall={false} bold>
                    {alldata ? alldata.namedeceased : "loading"}
                  </Text>
                  <Text scaleSmall={true}>
                    வயது {alldata ? alldata.age : "loading"}
                  </Text>
                  <Text scaleSmall={true}>
                    {alldata ? alldata.birthplace : "loading"} (பிறந்த இடம்){" "}
                  </Text>
                </Column>
              </ContentContainer>
              <WishesContainer>
                <div class="containerpic">
                  <img
                    src={Headstoneimg}
                    style={{ width: 306, height: 380, marginTop: 40 }}
                  />

                  <Carousel
                    autoplaySpeed={7000}
                    dotPosition={"right"}
                    autoplay
                    // effect="fade"
                    class="centeredpic"
                    style={{
                      marginTop: "-50%",
                      width: 200,
                      marginLeft: "22%",
                      marginRight: 0,
                    }}
                  >
                    {tributedata.map((sitem) => (
                      <div style={{ height: 300 }}>
                        <p style={{ color: "green" }}>
                          {sitem.title}{" "}
                          <span style={{ color: "red" }}>
                            -{sitem.postedBy}
                          </span>
                        </p>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </WishesContainer>
            </MainContainer>

            <MainContainer>
              <ContentContainer style={{ background: "white" }}>
                <Column
                  style={{
                    width: "80%",
                    margin: "0 auto",
                  }}
                >
                  <HairlineSeperator />
                  <Text scaleSmall={false} isBlack bold>
                    Deceased person Details
                  </Text>
                  <HairlineSeperator />
                  <List
                    dataSource={basedata}
                    renderItem={(item) => (
                      <>
                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">Fullname :</span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.title + item.name}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">City :</span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.citydeceased}
                              </span>
                            }
                          />
                        </List.Item>

                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Date of birth :
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.dateofbirth}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Date of death :
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.dateofdeath}{" "}
                              </span>
                            }
                          />
                        </List.Item>

                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">Birth place :</span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.birthplace}{" "}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">Death place :</span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.deathplace}{" "}
                              </span>
                            }
                          />
                        </List.Item>

                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">Job Title :</span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.jobtitle}{" "}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Last employment:
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.lastemployment}{" "}
                              </span>
                            }
                          />
                        </List.Item>
                      </>
                    )}
                  />
                  <HairlineSeperator />
                  <Text scaleSmall={false} isBlack bold>
                    Final Description
                  </Text>
                  <HairlineSeperator />
                  <div
                    className="descriptionView"
                    dangerouslySetInnerHTML={{
                      __html: alldata ? alldata.description : "loading",
                    }}
                  />
                  <HairlineSeperator />
                  <Text scaleSmall={false} isBlack bold>
                    Informer Details
                  </Text>
                  <HairlineSeperator />
                  <List
                    dataSource={basedata}
                    renderItem={(item) => (
                      <>
                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Fullname of the informer :
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.iname}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Informer’s E- mail::
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.iemail}
                              </span>
                            }
                          />
                        </List.Item>

                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Informer’s current address:
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.iaddress}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Informer’s Phone no:
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.imobile}{" "}
                              </span>
                            }
                          />
                        </List.Item>
                      </>
                    )}
                  />
                  <HairlineSeperator />
                  <Text scaleSmall={false} isBlack bold>
                    Next of Kin Details
                  </Text>
                  <HairlineSeperator />
                  <List
                    dataSource={basedata}
                    renderItem={(item) => (
                      <>
                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">Fullname:</span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.kfullname}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Relations hip to the deceased:
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.krelationship}
                              </span>
                            }
                          />
                        </List.Item>

                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">Phone Number</span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.kphone}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">Email address:</span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.kemail}{" "}
                              </span>
                            }
                          />
                        </List.Item>
                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">City:</span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.kcity}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">Postal code:</span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.kpostalcode}{" "}
                              </span>
                            }
                          />
                        </List.Item>
                      </>
                    )}
                  />
                  <HairlineSeperator />
                  <Text scaleSmall={false} isBlack bold>
                    Viewing Address and other details.
                  </Text>
                  <HairlineSeperator />
                  <List
                    dataSource={basedata}
                    renderItem={(item) => (
                      <>
                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Viewing Address:
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.kviewlocation}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Viewing Date and Time
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.kviewingdatetime}
                              </span>
                            }
                          />
                        </List.Item>
                      </>
                    )}
                  />
                  <HairlineSeperator />
                  <Text scaleSmall={false} isBlack bold>
                    Ceremony Address and other details.
                  </Text>
                  <HairlineSeperator />
                  <List
                    dataSource={basedata}
                    renderItem={(item) => (
                      <>
                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Ceremony Address:
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.kceremonylocation}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Ceremony Date and Time
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.kceremonydatetime}
                              </span>
                            }
                          />
                        </List.Item>
                      </>
                    )}
                  />
                  <HairlineSeperator />
                  <Text scaleSmall={false} isBlack bold>
                    Burial/Cremation and other details.
                  </Text>
                  <HairlineSeperator />
                  <List
                    dataSource={basedata}
                    renderItem={(item) => (
                      <>
                        <List.Item>
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Burial/Cremation Address:
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.kburiallocation}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            avatar={<BsBookmarkPlus />}
                            title={
                              <span style={listStyles}>
                                <span className="listname">
                                  Burial/Cremation Date and Time
                                </span>{" "}
                              </span>
                            }
                            description={
                              <span className="listdescription">
                                {item.kburialdateandtime}
                              </span>
                            }
                          />
                        </List.Item>
                      </>
                    )}
                  />

                  <HairlineSeperator />
                  <Text scaleSmall={false} isBlack bold>
                    Contact Details
                  </Text>
                  <HairlineSeperator />
                  <List
                    dataSource={alldata ? alldata.contact : ""}
                    renderItem={(item) => (
                      <List.Item key={item._id}>
                        <List.Item.Meta
                          avatar={<BsExclamationDiamond />}
                          title={
                            <span style={listStyles}>
                              <span className="listname"> Contcat Name: :</span>{" "}
                            </span>
                          }
                          description={
                            <span className="listdescription">
                              {item.contactname}
                            </span>
                          }
                        />
                        <List.Item.Meta
                          avatar={<BsPhone />}
                          title={
                            <span style={listStyles}>
                              <span className="listname">Contcat Mobile :</span>{" "}
                            </span>
                          }
                          description={
                            <span className="listdescription">
                              {item.contactmobile}
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                  {/* /ALL Images */}
                  <HairlineSeperator />
                  <Text scaleSmall={false} isBlack bold>
                    All Images
                  </Text>
                  <HairlineSeperator />
                  <Row>
                    {imgArray.map((secondsubitem) => (
                      <img
                        className="itemimgdisplay"
                        src={secondsubitem}
                        style={{
                          borderRadius: 5,
                          height: 100,
                          width: 80,
                          marginRight: 10,
                        }}
                      />
                    ))}
                  </Row>
                  {/* /ALL TRIBUTES */}
                  <HairlineSeperator />
                  <Text scaleSmall={false} isBlack bold>
                    All Tributes
                  </Text>
                  <HairlineSeperator />
                  <List
                    dataSource={tributes}
                    renderItem={(item) => (
                      <List.Item key={item._id}>
                        <List.Item.Meta
                          avatar={<BsChatQuote />}
                          title={
                            <span style={listStyles}>
                              <span className="listname">
                                {" "}
                                {item.postedBy}{" "}
                              </span>{" "}
                            </span>
                          }
                          description={
                            <span className="listdescription">{item.text}</span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Column>
              </ContentContainer>
            </MainContainer>
          </>
        )}
      </Container>
    </>
  );
}

const listStyles = {
  color: "gray",
  fontSize: 19,
  marginRight: 10,
};

export default Index;
