import React, { useState, useEffect } from "react";
import { Collapse, List, Spin, Switch, Button } from "antd";
import axios from "axios";
import moment from "moment";
import BASE_URL from "../../constant/index";
import {
  MessageOutlined,
  UserDeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import swal from "sweetalert";
const { Panel } = Collapse;

const Index = () => {
  const [loading, setLoading] = useState(true);
  const [alldata, setAlldata] = useState([]);

  // Axios headers
  const axiosHeaders = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };

  const activateUser = (_id) => {
    setLoading(true);
    axios({
      method: "put",
      url: `${BASE_URL}/activeuser`,
      headers: axiosHeaders,
      data: {
        _id: _id,
      },
    }).then((response) => {
      swal({
        timer: 3000,
        title: "Activated",
        text: "sucessfully Activated user !",
        icon: "success",
        button: "Okey",
        button: false,
      });
      refreshData();
      setLoading(false);
    });
  };

  const deactivateUser = (_id) => {
    setLoading(true);
    axios({
      method: "put",
      url: `${BASE_URL}/deactiveuser`,
      headers: axiosHeaders,
      data: {
        _id: _id,
      },
    }).then((response) => {
      swal({
        timer: 3000,
        title: "De activated",
        text: "sucessfully De-Activated user !",
        icon: "warning",
        button: "Okey",
        button: false,
      });
      refreshData();
      setLoading(false);
    });
  };

  // Get all data request
  const refreshData = () => {
    // setLoading(true);

    axios({
      method: "get",
      url: `${BASE_URL}/allusers`,
      headers: axiosHeaders,
    }).then((response) => {
      console.log(response.data.allusers);
      setAlldata(response.data.allusers);
      setLoading(false);
    });
  };

  useEffect(() => {
    refreshData();
  }, [alldata]);

  return (
    <div>
      {loading ? (
        <Spin style={{ width: "100%", marginTop: "40vh" }} size="large" />
      ) : (
        <List
          header={
            <h3
              style={{
                color: "violet",
                fontSize: 20,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              All Users ...
            </h3>
          }
          dataSource={alldata}
          renderItem={(item) => (
            <>
              <Collapse
                accordion={true}
                bordered={true}
                defaultActiveKey={item[0]}
              >
                <Panel
                  header={
                    <>
                      <span>{item.name}</span>
                    </>
                  }
                  key={item._id}
                  extra={
                    <>
                      {item.admin ? (
                        <span
                          style={{
                            marginLeft: 10,
                            backgroundColor: "tomato",
                            padding: 5,
                            color: "white",
                            borderRadius: 5,
                            fontSize: 14,
                            fontWeight: "100",
                          }}
                        >
                          Admin
                        </span>
                      ) : (
                        <span
                          style={{
                            marginLeft: 10,
                            backgroundColor: "violet",
                            padding: 5,
                            color: "white",
                            borderRadius: 5,
                            fontSize: 14,
                            fontWeight: "100",
                          }}
                        >
                          Users
                        </span>
                      )}
                    </>
                  }
                >
                  <List.Item>
                    <List.Item.Meta
                      avatar={<MessageOutlined />}
                      title={
                        <span style={listStyles}>
                          <span className="listname">Active status :</span>{" "}
                        </span>
                      }
                      description={
                        item.loginstatus ? (
                          <span
                            style={{
                              padding: 7,
                              borderRadius: 3,
                              backgroundColor: "green",
                              color: "white",
                            }}
                          >
                            <CheckOutlined /> User can login
                          </span>
                        ) : (
                          <span
                            style={{
                              padding: 7,
                              borderRadius: 3,
                              backgroundColor: "red",
                              color: "white",
                            }}
                          >
                            <CloseOutlined /> User can't login
                          </span>
                        )
                      }
                    />
                  </List.Item>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<MessageOutlined />}
                      title={
                        <span style={listStyles}>
                          <span className="listname">Email :</span>{" "}
                        </span>
                      }
                      description={
                        <span className="listdescription">{item.email}</span>
                      }
                    />
                    <List.Item.Meta
                      avatar={<UserDeleteOutlined />}
                      title={
                        <span style={listStyles}>
                          <span className="listname">Cancel Lisence :</span>{" "}
                        </span>
                      }
                      description={
                        <>
                          {!item.loginstatus ? (
                            <span
                              onClick={() => activateUser(item._id)}
                              style={{
                                backgroundColor: "green",
                                color: "white",
                                padding: 7,
                                borderRadius: 5,
                              }}
                            >
                              Activate user
                            </span>
                          ) : (
                            <span
                              onClick={() => deactivateUser(item._id)}
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                padding: 7,
                                borderRadius: 5,
                              }}
                            >
                              Deactivate user
                            </span>
                          )}

                          <p style={{ color: "red", marginTop: 10 }}>
                            **If you cancelled lisence they can't login..
                          </p>
                        </>
                      }
                    />
                  </List.Item>
                </Panel>
              </Collapse>
            </>
          )}
        />
      )}
    </div>
  );
};

const listStyles = {
  color: "gray",
  fontSize: 19,
  marginRight: 10,
};

export default Index;
